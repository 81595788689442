export default function TransaksiReferral(){
    return {
        no: null,
        id: null,
        alamat: null,
        catatan: null,
        cetakan: null,
        dataPenunjangs: null,
        diagnosa: null,
        fee: null,
        jejakTerakhir: null,
        jejakTerakhirStage: null,
        jejakTerakhirTimestamp: null,
        jejaks: null,
        jenisPenjamin: null,
        kelas: null,
        medicalRecordNo: null,
        nama: null,
        nextJejak: null,
        NomorRegistrasi: null,
        nomorSurat: null,
        perujuk: null,
        skemaRujukanFee: null,
        status: null,
        tanggalLahir: null,
        telepon: null,
        tindakan: null,
        totalBiaya: null,
        tujuanRujukan: null
    }
}