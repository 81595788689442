<template>
    <div>
        <base-header base-title="Report Detail Transaksi"></base-header>
        <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
            <div class="kt-portlet kt-portlet--mobile">
                <div class="kt-portlet__head kt-portlet__head--lg">
                    <base-title :head-title="headTitle"></base-title>
                </div>
                <div class="kt-portlet__body">
                    <div class="row">
                        <div class="col-md-9">
                            <div class="row">
                                <div class="col-md-5">
                                    <div class="form-group row">
                                        <label class="col-md-4 col-form-label">{{ $t('common.perujuk') }} :</label>
                                        <div class="col-md-8">
                                            <perujuk-select
                                                v-model="perujuk"
                                                :id-select-picker="'selectPerujuk'"
                                                :selected-picker="perujuk"
                                                :is-validate="true"
                                                :placeholder="'Semua'"
                                            ></perujuk-select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-5">
                                    <div class="form-group row">
                                        <label class="col-md-4 col-form-label">{{ $t('common.periode') }} :</label>
                                        <div class="col-md-8 pt-1">
                                            <div class="input-group input-group-sm date">
                                                <div class="input-group-prepend">
                                                <span class="input-group-text">
                                                    <i class="la la-calendar-o glyphicon-th"></i>
                                                </span>
                                                </div>
                                                <date-range-picker
                                                :changedValueStart.sync="dateStart"
                                                :changedValueEnd.sync="dateEnd"
                                                id-date-picker="filter_rangedatepicker"
                                                picker-value
                                                place-holder="DD/MM/YYYY - DD/MM/YYYY"
                                                ></date-range-picker>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="form-group row">
                                        <button
                                            type="button"
                                            @click="submitSearch()"
                                            class="btn btn-sm btn-default btn-bold btn-upper mt-1 ml-2 "
                                            >
                                            <i class="flaticon2-search"></i> {{ $t('button.search') }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                                <div id="btn_export_container">
                                </div>
                        </div>


                    </div>
                    <div v-if="showLoading" class="m-auto p-5 d-flex flex-row h-100">
                        <div class="kt-spinner kt-spinner--v2 kt-spinner--lg kt-spinner--primary my-auto"></div>
                        <h5 class="my-auto pl-5">Loading ...</h5>
                    </div>
                    <div v-if="isLoaded">
                        <data-tables :table="table"></data-tables>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import CheckPermission from "./../../../services/checkPermission.service";
const checkPermission = new CheckPermission();
import BaseTitle from "./../../_base/BaseTitle";
import BaseHeader from "./../../_base/BaseHeader";
import DateRangePicker from "./../../_general/DateRangePicker";
import DaftarPerujuk from "./../../../constants/report-daftar-perujuk";
import TransaksiReferral from "./../../../constants/report-transaksi-referral";
import DataTables from "./../../_table/DataTables";
import { RepositoryFactory } from "./../../../repositories/RepositoryFactory";
import PerujukSelect from "./../../_select/Perujuk";
import JejakEnum from "./../../../constants/jejak-enum";
const ReportRepository = RepositoryFactory.get("report");
export default {
    components: {
        BaseHeader,
        BaseTitle,
        DateRangePicker,
        DataTables,
        PerujukSelect,
    },
    data() {
        var vx = this;
        return {
            headTitle: "Report Detail Transaksi",
            dateStart: null,
            dateEnd: null,
            perujuk:null,
            showLoading:false,
            isLoaded:false,
            jejakEnum: JejakEnum,
            tableName: "table_detail_transaksi",
            mainTitle: "Laporan Detail Transaksi",
            subTitle: "",
            table: {
                tableName: "table_detail_transaksi",
                dateFormat: "dd-M-yyyy",
                tableDataResult: [],
                tableColumnDefs: [
                    {
                        defaultContent: " ",
                        targets: "_all"
                    },
                    {
                        targets: 6,
                        render: (data, type, row, meta) => {
                            const datajejaks = vx.findTimeStamp(data, 'enumStage', vx.jejakEnum.PASIEN_DATANG);
                            return datajejaks ? vx.formatDate(datajejaks.timestamp) : null;
                        }
                    },
                    {
                        targets: 7,
                        render: (data, type, row, meta) => {
                            const datajejaks = vx.findTimeStamp(data, 'enumStage', vx.jejakEnum.PASIEN_PULANG);
                            return datajejaks ? vx.formatDate(datajejaks.timestamp) : null;
                        }
                    },
                    {
                        targets: 8,
                        className: 'text-right',
                        render: (data, type, row, meta) => {
                           return (data || data !== '') ? vx.convertSeperator(data) : null;

                        }
                    },
                    {
                        targets: 9,
                        className: 'text-right',
                        render: (data, type, row, meta) => {
                            return (data || data !== '') ? vx.convertSeperator(data) : null;

                        }
                    },
                    {
                        targets: 10,
                        render: (data, type, row, meta) => {
                            const datajejaks = vx.findTimeStamp(data, 'enumStage', vx.jejakEnum.SUDAH_TERBAYARKAN);
                            return datajejaks ? vx.formatDate(datajejaks.timestamp) : null;
                        }
                    },
                    {
                        targets: 11,
                        render: (data, type, row, meta) => {
                            if (row.diagnosa && row.catatan ) {
                                return `${row.diagnosa}, <br /> ${row.catatan}`;
                            } else if (row.diagnosa && !row.catatan ) {
                                return `${row.diagnosa}`;
                            } else if (!row.diagnosa && row.catatan) {
                                return `${row.catatan}`;
                            } else {
                                return null;
                            }
                        }
                    },
                    {
                        targets: 12,
                        visible: false
                    },
                ],
                buttons:[
                    {
                        extend: 'excelHtml5',
                        title: "Laporan Detail Transaksi",
                        messageTop: vx.subTitle,
                        autoFilter: false,
                        exportOptions: {
                            columns: [0,1,2,3,4,5,6,7,8,9,10,11],
                            format: {
                                body(data, row, column, node) {
                                   if (column === 11) {
                                        data = data.replace(/<br\s*\/?>/ig, "");
                                    }
                                    if(column === 0 && isNaN(data)){
                                        data = data.replace(/<(\/)?(b\b)[^>]*>/g, '')
                                        data = 'Perujuk : ' + data
                                    }
                                    return [8, 9].includes(column) ? data.replace(/\./g, '').replace(',', '.') : data;
                                }
                            }
                        },
                        customize: function( xlsx ) {
                            var sheet = xlsx.xl.worksheets['sheet1.xml'];
                            let mergeCells = $('mergeCells', sheet)
                            var col = $('col', sheet);
                            $(col[0]).attr('width', 6);
                            $('row c[r^="A"]', sheet).each(function(index, item) {
                                if(index > 2){
                                    let row = index+1
                                    if($('is t', this).text()){
                                        $(this).attr('s', '2')
                                        mergeCells[0].appendChild(_createNode(sheet, 'mergeCell', {
                                            attr: {
                                                ref: 'A'+row+':'+'L'+row
                                            }
                                        }))
                                        mergeCells.attr('count', Number(mergeCells.attr('count'))+1)

                                    } else {
                                        $(this).attr('s', '51')
                                    }
                                }
                            })

                            // create elementNode
                            function _createNode( doc, nodeName, opts ) {
                                var tempNode = doc.createElement( nodeName );

                                if ( opts ) {
                                    if ( opts.attr ) {
                                        $(tempNode).attr( opts.attr );
                                    }

                                    if ( opts.children ) {
                                        $.each( opts.children, function ( key, value ) {
                                            tempNode.appendChild( value );
                                        } );
                                    }

                                    if ( opts.text !== null && opts.text !== undefined ) {
                                        tempNode.appendChild( doc.createTextNode( opts.text ) );
                                    }
                                }

                                return tempNode;
                            }
                        }

                    },
                    {
                        extend: 'pdfHtml5',
                        orientation: 'landscape',
                        pageSize: 'A4',
                        title: 'Laporan Detail Transaksi',
                        exportOptions: {
                            // columns: ':visible',
                            columns: [0,1,2,3,4,5,6,7,8,9,10,11],
                        },
                        customize: function ( doc ) {
                            if(vx.perujuk){
                                doc.content.splice( 1, 0,
                                    {
                                        text: [
                                            {text : "Nama : ", bold: true},
                                            {text : vx.fullName +" \n"},
                                            {text : "Nama Faskes : ", bold: true},
                                            {text : (vx.perujuk.alamatPerujuks && vx.perujuk.alamatPerujuks.length > 0 ) ? vx.perujuk.alamatPerujuks[0].nama : ''},
                                            {text :" \n"},
                                            {text : "Alamat      : ", bold: true},
                                            {text : (vx.perujuk.alamatPerujuks && vx.perujuk.alamatPerujuks.length > 0 ) ? vx.perujuk.alamatPerujuks[0].jalan : ''},
                                            {text :" \n"},
                                            {text : "Telepon     : ", bold: true},
                                            {text : vx.perujuk.teleponPerujuks[0].nomor+" \n"},
                                            {text : "Periode     : ", bold: true},
                                            {text : vx.formatDate(vx.dateStart) + " - " + vx.formatDate(vx.dateEnd) +"\n \n"}
                                        ],
                                        alignment: 'left'
                                    }
                                )
                            } else {
                                doc.content.splice( 1, 0,
                                    {
                                        text: [
                                            {text : "Perujuk     : ", bold: true},
                                            {text : "Semua" +" \n"},
                                            {text : "Periode     : ", bold: true},
                                            {text : vx.formatDate(vx.dateStart) + " - " + vx.formatDate(vx.dateEnd) +"\n \n"}
                                        ],
                                        alignment: 'left'
                                    }
                                )
                            }
                        }
                    },
                    {
                        extend: 'print',
                        exportOptions: {
                            // columns: ':visible'
                            columns: [0,1,2,3,4,5,6,7,8,9,10,11],
                        },
                        title: 'Laporan Detail Transaksi',
                        header: true,
                        footer: true,
                        text: 'Print Laporan',
                        titleAttr: 'Print',
                        customize: win => {
                            let template
                            if(vx.perujuk){
                                template = `
                                    <div class="col-5 text-left">
                                        <div class="row"><h5>: ${vx.fullName}</h5></div>
                                        <div class="row"><h5>: ${ (vx.perujuk.alamatPerujuks && vx.perujuk.alamatPerujuks.length > 0 ) ?
                                        vx.perujuk.alamatPerujuks[0].nama : ''}</h5></div>
                                        <div class="row"><h5>: ${(vx.perujuk.alamatPerujuks && vx.perujuk.alamatPerujuks.length > 0 ) ?
                                        vx.perujuk.alamatPerujuks[0].jalan : ''}</h5></div>
                                        <div class="row"><h5>: ${vx.perujuk.teleponPerujuks[0].nomor}</h5></div>
                                        <div class="row"><h5>: ` + vx.formatDate(vx.dateStart) + ` - ` + vx.formatDate(vx.dateEnd) +
                                    `</h5></div>
                                    </div>
                                `
                            } else {
                                template = `
                                    <div class="col-5 text-left">
                                        <div class="row"><h5>: Semua </h5></div>
                                        <div class="row"><h5>: -</h5></div>
                                        <div class="row"><h5>: -</h5></div>
                                        <div class="row"><h5>: -</h5></div>
                                        <div class="row"><h5>: ` + vx.formatDate(vx.dateStart) + ` - ` + vx.formatDate(vx.dateEnd) +
                                        `</h5></div>
                                    </div>
                                `
                            }
                            $(win.document.body)
                                .find('h1')
                                .after(
                                    `
                                        <div class="row mt-5 mb-3 mx-0 px-0">
                                            <div class="col-2 text-left">
                                                <div class="row"><h5>NAMA PERUJUK</h5></div>
                                                <div class="row"><h5>NAMA FASKES</h5></div>
                                                <div class="row"><h5>ALAMAT</h5></div>
                                                <div class="row"><h5>TELP</h5></div>
                                                <div class="row"><h5>Periode</h5></div>
                                            </div>
                                            ${ template }
                                            <div class="col-5 text-right">
                                                <img src="http://www.mandayahospital.com/wp-content/uploads/2018/01/LOGO-MANDAYA-MANDAYA-KARAWANG-resize.png" width="75%"/>
                                            </div>
                                        </div>`
                                );
                            $(win.document.body).css('font-size', '12pt');
                            $(win.document.body)
                                .find('table')
                                .addClass('compact')
                                .css('font-size', 'inherit');
                            $(win.document.body)
                                .find('h1')
                                .addClass('text-center mt-3 mb-5');
                            const css = '@page { size: landscape; }',
                                head = win.document.head || win.document.getElementsByTagName('head')[0],
                                style = win.document.createElement('style');
                            style.type = 'text/css';
                            style.media = 'print';
                            if (style.styleSheet) {
                                style.styleSheet.cssText = css;
                            } else {
                                style.appendChild(win.document.createTextNode(css));
                            }
                            head.appendChild(style);
                        }
                    }
                ],
                ordering:false,
                tableColumns: [
                    {
                        title: 'No.',
                        data: 'no'
                    },
                    {
                        title: 'Nama Pasien',
                        data: 'nama'
                    },
                    {
                        title: 'Medical Record',
                        data: 'medicalRecordNo'
                    },
                    {
                        title: 'Nomor Registrasi',
                        data: 'nomorRegistrasi'
                    },
                    {
                        title: 'Jenis Pelayanan',
                        data: 'tujuanRujukan.nama'
                    },
                    {
                        title: 'Jenis Penjamin',
                        data: 'jenisPenjamin.nama'
                    },
                    {
                        title: 'Tgl Masuk',
                        data: 'jejaks'
                    },
                    {
                        title: 'Tgl Keluar',
                        data: 'jejaks'
                    },
                    {
                        title: 'Nominal Transaksi',
                        data: 'totalBiaya'
                    },
                    {
                        title: 'Biaya Kesehatan',
                        data: 'fee'
                    },
                    {
                        title: 'Tgl Pembayaran',
                        data: 'jejaks'
                    },
                    {
                        title: 'Keterangan',
                        data: 'diagnosa'
                    },
                    {
                        title:"Actions",
                        data:"id"
                    }
                ],
                tableFilter: [
                    {
                        title: 'Nama Pasien',
                        type: 'text'
                    },
                    {
                        title: 'Medical Record',
                        type: 'text'
                    },
                    {
                        title: 'Nomor Registrasi',
                        type: 'text'
                    },
                    {
                        title: 'Jenis Pelayanan',
                        type: 'combobox'
                    },
                    {
                        title: 'Jenis Penjamin',
                        type: 'combobox'
                    },
                    {
                        title: 'Tgl Masuk',
                        type: 'datepicker'
                    },
                    {
                        title: 'Tgl Keluar',
                        type: 'datepicker'
                    },
                    {
                        title: 'Nominal Transaksi',
                        type: 'text'
                    },
                    {
                        title: 'Biaya Kesehatan',
                        type: 'text'
                    },
                    {
                        title: 'Tgl Pembayaran',
                        type: 'datepicker'
                    },
                    {
                        title: 'Keterangan',
                        type: 'text'
                    },
                    {
                        title: "Actions",
                        type: "actions"
                    }
                ]
            }
        }
    },
    provide() {
        return {
            $validator: this.$validator
        };
    },
    computed: {
        fullName: function () {
            if(this.perujuk){
                const firstNama = (this.perujuk.firstName) ? this.perujuk.firstName : '';
                const lastNama = (this.perujuk.lastName) ? this.perujuk.lastName : '';
                return firstNama + ' ' + lastNama;
            }
        }
    },
    methods: {
        convertSeperator: function(angka) {
            if (angka !== null) {
                const parts = angka.toString().split('.');
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                return parts.join(',');
            }
            return angka;
        },
        findTimeStamp: function(array, searchKey, searchValue) {
            if(array){
                const sortArray = array.sort((a,b) => moment(b.timestamp) - moment(a.timestamp));
                const index = sortArray.findIndex(x => x[searchKey] === searchValue);
                if (index >= 0) {
                    return sortArray[index];
                } else {
                    return null;
                }
            }
        },
        formatDate: function (data , format = "DD-MMM-YYYY") {
            var momentDate = moment(data);
            return(momentDate.format(format));
        },
        submitSearch: function () {
            var vx = this;
            this.$validator.validateAll().then(result => {
                if (result) {
                    vx.showLoading = true;
                    vx.isLoaded = false;
                    if(vx.perujuk){
                        const alamat = (vx.perujuk.alamatPerujuks && vx.perujuk.alamatPerujuks.length > 0 ) ?  vx.perujuk.alamatPerujuks[0].nama + ' - ' + vx.perujuk.alamatPerujuks[0].jalan : '';
                        vx.subTitle = 'Perujuk : ' + vx.fullName + ' | ' + alamat +
                                        ' ( ' + vx.perujuk.teleponPerujuks[0].nomor + ' ) ' + ', Periode : ' + vx.formatDate(vx.dateStart) + ' - ' + vx.formatDate(vx.dateEnd) ;
                        vx.table.buttons[0].messageTop = vx.subTitle;
                    } else {
                        vx.table.buttons[0].messageTop = 'Periode : ' + vx.formatDate(vx.dateStart) + ' - ' + vx.formatDate(vx.dateEnd);
                    }
                    vx.fetch();
                }
            })
        },
        async fetch(resolve) {
            let vx = this
            let params
            if(!this.perujuk){
                params = {
                    sort: 'perujuk.id,ASC',
                    jejaksStartDate: this.dateStart,
                    jejaksEndDate: this.dateEnd,
                    jejaksEnumStages: [this.jejakEnum.PASIEN_BELUM_MASUK]
                }
            }else(
                params = {
                    sort: 'perujuk.id,ASC',
                    jejaksStartDate: this.dateStart,
                    jejaksEndDate: this.dateEnd,
                    jejaksEnumStages: [this.jejakEnum.PASIEN_BELUM_MASUK],
                    perujukId: this.perujuk.id,
                }
            )
            new Promise((resolve, reject) => {
                ReportRepository.reportReferral(params, resolve, reject );
            })
            .then(response => {
                let data
                if(!this.perujuk){
                    data = this.constructDataGrouping(response.data)
                } else {
                    data = this.constructData(response.data);

                }
                if (this.isLoaded) {
                    var datatable = $("#" + this.tableName).DataTable();
                    datatable.clear();
                    datatable.rows.add(data);
                    datatable.draw("full-hold");
                    if(resolve !== undefined) {
                        resolve(data);
                    }
                } else {
                    this.table.tableDataResult = data;
                    this.showLoading = false;
                    this.isLoaded = true;
                }
            }).catch(error => {
                var msg = (error.message) ? error.message : error;
                this.showToastError('Detail Transaksi : ' + msg);
            })
        },
        constructData: function (data) {
            let result = [];
            data.forEach((transaksiReferral, i) => {
                const jejak = this.findTimeStamp(transaksiReferral.jejaks, 'enumStage', this.jejakEnum.PASIEN_BELUM_MASUK)
                const tanggalMasuk = moment(jejak.timestamp).format('YYYY-MM-DD');
                const tanggalAkhir = moment(this.dateEnd).format('YYYY-MM-DD');
                // if (tanggalAkhir.isSameOrAfter(tanggalMasuk)) {
                //     temp.push(transaksiReferral);
                // }
                if (tanggalAkhir >= tanggalMasuk) {
                    let temp = new TransaksiReferral()
                    temp.no = i+1
                    temp.id= transaksiReferral.id,
                    temp.alamat= transaksiReferral.alamat,
                    temp.catatan= transaksiReferral.catatan,
                    temp.cetakan= transaksiReferral.cetakan,
                    temp.dataPenunjangs= transaksiReferral.dataPenunjangs,
                    temp.diagnosa= transaksiReferral.diagnosa,
                    temp.fee= transaksiReferral.fee,
                    temp.jejakTerakhir= transaksiReferral.jejakTerakhir,
                    temp.jejakTerakhirStage= transaksiReferral.jejakTerakhirStage,
                    temp.jejakTerakhirTimestamp= transaksiReferral.jejakTerakhirTimestamp,
                    temp.jejaks= transaksiReferral.jejaks,
                    temp.jenisPenjamin= transaksiReferral.jenisPenjamin,
                    temp.kelas= transaksiReferral.kelas,
                    temp.medicalRecordNo= transaksiReferral.medicalRecordNo,
                    temp.nomorRegistrasi= transaksiReferral.nomorRegistrasi,
                    temp.nama= transaksiReferral.nama,
                    temp.nextJejak= transaksiReferral.nextJejak,
                    temp.NomorRegistrasi= transaksiReferral.NomorRegistrasi,
                    temp.nomorSurat= transaksiReferral.nomorSurat,
                    temp.perujuk= transaksiReferral.perujuk,
                    temp.skemaRujukanFee= transaksiReferral.skemaRujukanFee,
                    temp.status= transaksiReferral.status,
                    temp.tanggalLahir= transaksiReferral.tanggalLahir,
                    temp.telepon= transaksiReferral.telepon,
                    temp.tindakan= transaksiReferral.tindakan,
                    temp.totalBiaya= transaksiReferral.totalBiaya,
                    temp.tujuanRujukan= transaksiReferral.tujuanRujukan

                    result.push(temp);
                }
            });
            return result;
        },
        constructDataGrouping(data){
            let sortedData = []
            let count = 0
            let perujukId = 0
            data.forEach((transaksi, i) => {
                if(transaksi.perujuk){
                    const firstName = transaksi.perujuk.firstName ? transaksi.perujuk.firstName : '';
                    const lastName = transaksi.perujuk.lastName ? transaksi.perujuk.lastName  : '';
                    const kategori = transaksi.perujuk.kategoriNama ? ` - ${transaksi.perujuk.kategoriNama}` : ''
                    const perujukName = firstName + ' ' + lastName + kategori
                    if(perujukId != transaksi.perujuk.id){
                        count = 0
                        perujukId = transaksi.perujuk.id
                        let groupTransaksi = new TransaksiReferral()
                        groupTransaksi.no = '<b>'+perujukName+'</b>';
    
                        sortedData.push(groupTransaksi)
                    }
                    count = count + 1
                    let temp = new TransaksiReferral()
                    temp.no = count
                    temp.id= transaksi.id,
                    temp.alamat= transaksi.alamat,
                    temp.catatan= transaksi.catatan,
                    temp.cetakan= transaksi.cetakan,
                    temp.dataPenunjangs= transaksi.dataPenunjangs,
                    temp.diagnosa= transaksi.diagnosa,
                    temp.fee= transaksi.fee,
                    temp.jejakTerakhir= transaksi.jejakTerakhir,
                    temp.jejakTerakhirStage= transaksi.jejakTerakhirStage,
                    temp.jejakTerakhirTimestamp= transaksi.jejakTerakhirTimestamp,
                    temp.jejaks= transaksi.jejaks,
                    temp.jenisPenjamin= transaksi.jenisPenjamin,
                    temp.kelas= transaksi.kelas,
                    temp.medicalRecordNo= transaksi.medicalRecordNo,
                    temp.nomorRegistrasi= transaksi.nomorRegistrasi,
                    temp.nama= transaksi.nama,
                    temp.nextJejak= transaksi.nextJejak,
                    temp.NomorRegistrasi= transaksi.NomorRegistrasi,
                    temp.nomorSurat= transaksi.nomorSurat,
                    temp.perujuk= transaksi.perujuk,
                    temp.skemaRujukanFee= transaksi.skemaRujukanFee,
                    temp.status= transaksi.status,
                    temp.tanggalLahir= transaksi.tanggalLahir,
                    temp.telepon= transaksi.telepon,
                    temp.tindakan= transaksi.tindakan,
                    temp.totalBiaya= transaksi.totalBiaya,
                    temp.tujuanRujukan= transaksi.tujuanRujukan
                    sortedData.push(temp)
                }
            })
            return sortedData
        },
        showToastError: function(message) {
            toastr.error(message);
        }
    },
    created() {

    }
}
</script>
