export default function DaftarPerujuk() {
    return {
        no: null,
        kodePerujuk: null,
        namaPerujuk: null,
        tanggalDaftar: null,
        noAlamat: null,
        alamat: null,
        faskes: null,
        email: null,
        keterangan: null,
        noTelp1: null,
        noTelp2: null
    };
}
